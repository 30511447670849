<script>
export default {
	onLaunch: function () {
		// 锁定竖屏正方向
		// #ifdef APP
		plus.screen.lockOrientation('landscape-primary');
		// #endif
	},
	onShow: function () {},
	onHide: function () {}
};
</script>

<style lang="scss">
/*每个页面公共css */
@import '@/uni_modules/uv-ui-tools/index.scss';
@import '@/static/index.css';
@import '@/static/answer.scss';
page {
	background-color: #f5f5f5;
}
.hengColor {
	color: #002288;
}
// button 全局样式
.uv-button {
	border-radius: 14rpx !important;
	.uv-button__text {
		color: #fff;
		font-size: 25rpx !important;
	}
}
.htmlbox {
	:deep(._img) {
		max-width: 200rpx !important;
		min-height: 150rpx;
		object-fit: contain;
	}
}
:deep(.uv-button--info) {
	color: #fff !important;
	background-color: #002288 !important;
}

:deep(.uv-button--plain) {
	background: none !important;
	.uv-button__text {
		color: #002288;
	}
}

:deep(.input-placeholder) {
	color: #999999 !important;
	font-size: 28rpx;
}

:deep(.uv-input__content__prefix-icon) {
	margin-right: 0 !important;
}
</style>
